<script lang="ts">
const intervalMS = 1000 * 60
</script>
<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { toast } from 'vue-sonner'

 // 1 minutes

const { t } = useI18n()

let toastId: string | number | undefined
let updateToastId: string | number | undefined

const updateServiceWorker = useRegisterSW({
  onRegistered(r) {
    if (!r)
      return

    console.info('ψ(._. )> service worker registered', r)

    setInterval(() => {
      r.update()
    }, intervalMS)

    r.addEventListener('updatefound', () => {
      console.info('ψ(._. )>  update found', r)
      toastId !== undefined && toast.dismiss(toastId)
      toastId = toast(t('updateDownloading'), {
        important: true,
        duration: Infinity,
      })
    })
  },
  onNeedRefresh() {
    toastId !== undefined && toast.dismiss(toastId)
    updateToastId !== undefined && toast.dismiss(updateToastId)
    updateToastId = toast(t('needRefresh'), {
      important: true,
      duration: Infinity,
      action: {
        label: t('update'),
        onClick: () => {
          updateServiceWorker.updateServiceWorker(true)
        },
      },
    })
  },
  onOfflineReady() {},
})
</script>

<template>
  <div />
</template>

<style scoped>

</style>
